import React from "react";
import { Link } from "react-router-dom";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGlobalContext } from "../../context/useGlobalContext";
import ProductCard from "../product-card/ProductCard";

function FeaturedBundlesList() {
  const { bundles } = useGlobalContext();
  return (
    <section className="relative">
      <div className="overflow-x-hidden px-5 sm:px-6 md:mx-20 xl:mx-40">
        {/* Featured Bundles content */}
        <div className="pb-12 md:pb-20">
          {/* Header */}
          <div className="mx-auto mb-10 flex 2xl:w-5/6">
            <div className="flex-wrap justify-start text-left text-2xl font-medium text-white md:text-5xl">
              Featured Bundles
            </div>
            <div className="flex-grow" />
            <div className="flex items-end justify-end">
              <Link
                to="/store"
                className="flex items-center border-b-[1px] border-transparent text-secondary-200 transition duration-150 ease-in-out hover:border-b hover:border-white hover:text-white"
              >
                <p className="text-left text-lg font-semibold md:text-2xl ">
                  View all
                </p>
                <svg
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-3 h-6 w-6 fill-current"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path d="M13.2197 19.0303C13.5126 19.3232 13.9874 19.3232 14.2803 19.0303L20.5303 12.7803C20.8232 12.4874 20.8232 12.0126 20.5303 11.7197L14.2803 5.46967C13.9874 5.17678 13.5126 5.17678 13.2197 5.46967C12.9268 5.76256 12.9268 6.23744 13.2197 6.53033L18.1893 11.5L3.75 11.5C3.33579 11.5 3 11.8358 3 12.25C3 12.6642 3.33579 13 3.75 13L18.1893 13L13.2197 17.9697C12.9268 18.2626 12.9268 18.7374 13.2197 19.0303Z" />
                </svg>
              </Link>
            </div>
          </div>
          {/* Bundles list */}
          <div className="mb-8 2xl:mx-auto 2xl:w-5/6">
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              navigation
              breakpoints={{
                640: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1280: {
                  slidesPerView: 4,
                },
              }}
              modules={[Navigation]}
              className="py-5"
            >
              {bundles &&
                bundles.length > 0 &&
                bundles
                  .sort((a, b) => b.price.amount - a.price.amount)
                  .map((bundle, index) => (
                    <SwiperSlide key={index}>
                      <div className="flex justify-center">
                        <ProductCard
                          product={bundle}
                          id={bundle.id}
                          img={bundle.sellixProducts[0].imageUrl}
                          name={bundle.name}
                          version={`${bundle.pluginIds.length} Plugins`}
                          price={bundle.price.amount}
                          category={"Bundle"}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturedBundlesList;
