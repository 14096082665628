import React, { useEffect, useState } from "react";

interface GifPlayerProps {
  gif1: string;
  gif2: string;
  gif1Duration: number;
  gif2Duration: number;
}

const GifPlayer: React.FC<GifPlayerProps> = ({ gif1, gif2, gif1Duration, gif2Duration }) => {
  const [currentGif, setCurrentGif] = useState<string>(gif1);

  useEffect(() => {
    let gifTimeout: NodeJS.Timeout;

    if (currentGif === gif1) {
      // Switch to the second GIF after the first one finishes
      gifTimeout = setTimeout(() => setCurrentGif(gif2), gif1Duration);
    } else {
      // Switch back to the first GIF after the second one finishes
      gifTimeout = setTimeout(() => setCurrentGif(gif1), gif2Duration);
    }

    // Cleanup timeout when the component unmounts or the GIF changes
    return () => clearTimeout(gifTimeout);
  }, [currentGif, gif1, gif2, gif1Duration, gif2Duration]);

  return (
    <img
      className="rounded-3xl border-transparent xl:h-[454px] xl:w-[454px]"
      src={currentGif}
      alt="GIF Animation"
    />
  );
};

export default GifPlayer;
