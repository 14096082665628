import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import ProductCard from "../../components/product-card/ProductCard";
import { useGlobalContext } from "../../context/useGlobalContext";
import {
  IdentifiedSellixBundle,
  SellixPluginsGet,
} from "../../services/sellix";

const BundleDetails = memo(function BundleDetails() {
  const { setSelectedProduct } = useGlobalContext();

  const { id } = useParams();
  const { bundles, plugins } = useGlobalContext();

  const [bundle, setBundle] = useState<IdentifiedSellixBundle | undefined>();

  useEffect(() => {
    const foundBundle: IdentifiedSellixBundle | undefined = bundles.find(
      (p) => p.id === id,
    );
    setBundle(foundBundle);
  }, [id, bundles]);

  if (!bundle) {
    return (
      <div className="flex min-h-screen flex-col overflow-hidden">
        <Header />
        <div className="flex-grow"></div>
        <Footer />
      </div>
    );
  }

  const bundlePlugins = plugins.filter((plugin) =>
    bundle.pluginIds.includes(plugin.id),
  );

  const handlePurchase = () => {
    setSelectedProduct(bundle);
  };

  const swiperComponent = (title: string, pluginsList?: SellixPluginsGet) => {
    return (
      <div className="mb-8 2xl:mx-auto 2xl:w-5/6">
        <h2 className="mb-3 mt-3 border-b-1 border-primary-100 text-lg font-bold text-primary-100">
          {title}
        </h2>
        <Swiper
          spaceBetween={10}
          slidesPerView={4}
          navigation
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            1280: {
              slidesPerView: 3,
            },
            1800: {
              slidesPerView: 4,
            },
          }}
          modules={[Navigation]}
          className="py-5"
        >
          {pluginsList &&
            pluginsList.length > 0 &&
            pluginsList.map((plugin, index) => (
              <SwiperSlide key={index}>
                <div className="flex justify-center rounded-lg bg-black bg-opacity-30 p-[2px]">
                  <ProductCard
                    product={plugin}
                    id={plugin.id}
                    img={
                      plugin.price.amount > 0
                        ? plugin.sellixProducts[0].imageUrl
                        : `/assets/${plugin.id}/thumbnail.jpg`
                    }
                    name={plugin.name}
                    version={plugin.version}
                    price={plugin.price.amount}
                    categories={plugin.categories}
                  />
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    );
  };

  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      <Header />

      <div className="pt-10 md:pt-40">
        <section className="">
          <div className="pb-12 md:pb-20">
            <div className="mb-10">
              <h1
                className={`text-center text-3xl font-medium text-white sm:text-4xl md:text-5xl lg:text-6xl`}
                data-aos="slide-right"
                data-aos-delay="150"
              >
                {bundle.name} Bundle
              </h1>
            </div>
            <div className="flex flex-col justify-center ">
              <div className="mx-4 flex flex-col justify-center lg:flex-row">
                {/* Left Panel */}
                <div
                  className="w-full rounded-lg border-black bg-background-500 p-4 font-semibold text-white shadow-rightBottom lg:w-2/3"
                  data-aos="slide-up"
                  data-aos-delay="150"
                >
                  <div className="mb-5 flex flex-col justify-between">
                    <span className="mb-2 text-left text-sm italic text-yellow-300">
                      Type: Bundle
                    </span>
                  </div>
                  <div className="mb-8 w-full">
                    <h2 className="mb-3 mt-3 border-b-1 border-primary-100 text-lg font-bold text-primary-100">
                      Description
                    </h2>
                    <p className="text-sm">{bundle.description}</p>
                  </div>
                  {/* ReadMe File */}
                  {bundles.length > 0 && (
                    <>
                      <div className="mb-8 w-full">
                        {bundlePlugins.length > 0 && (
                          <>
                            {swiperComponent(
                              "Plugins List",
                              bundlePlugins.filter(
                                (plugin) => plugin.price.amount > 0,
                              ),
                            )}
                          </>
                        )}
                        {bundlePlugins.filter(
                          (plugin) => plugin.price.amount === 0,
                        ).length > 0 && (
                          <>
                            <h2 className="mb-4 text-center text-3xl font-bold">
                              Free with the purchase of this bundle!
                            </h2>
                            {swiperComponent(
                              "Free Plugins List",
                              bundlePlugins.filter(
                                (plugin) => plugin.price.amount === 0,
                              ),
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {/* Right Panel */}
                <div
                  className="mx-4 w-auto self-center rounded-lg bg-background-500 font-semibold text-white shadow-rightBottom lg:w-80 lg:self-start"
                  data-aos="slide-left"
                  data-aos-delay="150"
                >
                  <img
                    className="rounded-lg rounded-tl-none rounded-tr-none lg:rounded-lg"
                    src={bundle.sellixProducts[0].imageUrl}
                    alt="Bundle Thumbnail"
                  />
                  {/* Right panel labels and buttons */}

                  <div className="space-y-5 px-5 pt-5">
                    <div className="flex justify-between">
                      <label className="text-right">Price</label>
                      <span className="ml-2 text-right">
                        {bundle.price.amount.toFixed(2)}€
                      </span>
                    </div>
                  </div>

                  <div className="mt-4 flex justify-center gap-y-5 px-5 py-4">
                    <button
                      className="btn-gradient mr-2 h-9 w-full rounded-xl font-semibold text-white transition-colors hover:bg-white"
                      onClick={handlePurchase}
                    >
                      Purchase
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
});

export default BundleDetails;
