// GlobalContext.tsx
import React, { createContext, useContext, useState } from 'react';
import { IdentifiedSellixBundle, IdentifiedSellixPlugin, IdentifiedSellixProduct } from '../services/sellix';

interface GlobalContextProps {
    selectedProduct: IdentifiedSellixBundle | IdentifiedSellixPlugin | null;
    setSelectedProduct: (product: IdentifiedSellixBundle | IdentifiedSellixPlugin | null) => void;
    sellixProduct: IdentifiedSellixProduct | null;
    setSellixProduct: (product: IdentifiedSellixProduct | null) => void;
    bundles: IdentifiedSellixBundle[];
    setBundles: (bundles: IdentifiedSellixBundle[]) => void;
    plugins: IdentifiedSellixPlugin[];
    setPlugins: (plugins: IdentifiedSellixPlugin[]) => void;
}

const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

export const GlobalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [selectedProduct, setSelectedProduct] = useState<IdentifiedSellixBundle | IdentifiedSellixPlugin | null>(null);
    const [sellixProduct, setSellixProduct] = useState<IdentifiedSellixProduct | null>(null);
    const [bundles, setBundles] = useState<IdentifiedSellixBundle[]>([]);
    const [plugins, setPlugins] = useState<IdentifiedSellixPlugin[]>([]);

    return (
        <GlobalContext.Provider value={{
            selectedProduct,
            setSelectedProduct,
            sellixProduct,
            setSellixProduct,
            bundles,
            setBundles,
            plugins,
            setPlugins
        }}>
            {children}
        </GlobalContext.Provider>
    );
};

export const useGlobalContext = () => {
    const context = useContext(GlobalContext);
    if (!context) {
        throw new Error("useGlobalContext must be used within a GlobalProvider");
    }
    return context;
};
