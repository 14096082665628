import React, { useEffect } from "react";
import { useGlobalContext } from "../../context/useGlobalContext";
import "./SellixEmbed.css";

const SellixEmbed = () => {
  const { sellixProduct, setSellixProduct } = useGlobalContext();

  const handleCloseModal = () => {
    setSellixProduct(null);
  };

  useEffect(() => {
    const modalCloseHandler = (event: MessageEvent) => {
      if (event.data === "close-embed") {
        handleCloseModal();
      }
    };

    window.addEventListener("message", modalCloseHandler);

    return () => {
      window.removeEventListener("message", modalCloseHandler);
    };
  }, []);

  let iframeUrl: string | undefined;
  if (sellixProduct) {
    const productId = sellixProduct.id; // Adjust this according to your context structure
    iframeUrl = `https://embed.sellix.io/product/${productId}`;
  }

  return (
    <>
      {sellixProduct && (
        <div className="sellix-modal">
          <div className="sellix-backdrop" onClick={handleCloseModal}></div>
          <div className="sellix-iframe-wrapper">
            <div className="sellix-iframe-content">
              <div className="sellix-iframe-loader-container">
                <img
                  src="https://cdn.sellix.io/static/embed/loader.png"
                  alt="Loader"
                  className="sellix-iframe-loader"
                  style={{ width: "35px" }}
                />
              </div>
              <iframe
                src={iframeUrl}
                className="sellix-iframe"
                title="Sellix Embed"
                onLoad={() => {
                  const loader = document.querySelector(
                    ".sellix-iframe-loader-container",
                  );
                  if (loader) {
                    loader.remove();
                  }
                }}
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SellixEmbed;
