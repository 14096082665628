import { Analytics } from "@vercel/analytics/react";
import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import PluginList from "../../components/plugin-list/PluginList";
import FeaturedBundlesList from "../../components/featured-bundles-list/FeaturedBundlesList";
import GettingStartedHome from "./GettingStartedHome";
import HeroHome from "./HeroHome";

export default function Home() {
  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      <Analytics />

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <HeroHome />
        <FeaturedBundlesList />
        <GettingStartedHome />
        <PluginList />
        <Footer />
      </main>
    </div>
  );
}
