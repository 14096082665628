import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import CartModal from "./components/cart-modal/CartModal";
import SellixEmbed from "./components/sellix-embed/SellixEmbed";
import About from "./pages/about/About";
import BundleDetails from "./pages/bundle-details/BundleDetails";
import GettingStarted from "./pages/getting-started/GettingStarted";
import Home from "./pages/home/Home";
import LoginSuccess from "./pages/login-success/LoginSuccess";
import PluginDetails from "./pages/plugin-details/PluginDetails";
import Plugins from "./pages/plugins/Plugins";
import Privacy from "./pages/policies/Privacy";
import Terms from "./pages/policies/Terms";
import Callback from "./pages/register/Callback";
import Register from "./pages/register/Register";
import Store from "./pages/store/Store";

const App = () => {
  const location = useLocation();
  useEffect(() => {
    Aos.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  useEffect(() => {
    const htmlElement = document.querySelector("html");
    if (htmlElement) {
      htmlElement.style.scrollBehavior = "auto";
      window.scrollTo({ top: 0 });
      htmlElement.style.scrollBehavior = "";
    }
  }, [location.pathname]);

  const DiscordRedirect = () => {
    useEffect(() => {
      window.location.href = "https://discord.gg/3bhXYtd8bt";
    }, []);
    return ("");
  }

  return (
    <>
      <CartModal />
      <SellixEmbed />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/callback" element={<Callback />} />
        <Route path="/plugins" element={<Plugins />} />
        <Route path="/plugin-details/:id" element={<PluginDetails />} />
        <Route path="/bundle-details/:id" element={<BundleDetails />} />
        <Route path="/store" element={<Store />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/getting-started" element={<GettingStarted />} />
        <Route path="/about" element={<About />} />
        <Route path="/login-success" element={<LoginSuccess />} />
        <Route path="/discord" element={<DiscordRedirect />} />
      </Routes>
    </>
  );
};

export default App;
