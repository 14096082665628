import React from "react";
import DownloadIcon from "../../assets/getting-started/download-icon.png";
import DiscordIcon from "../../assets/getting-started/discord-icon.png";
import BuyIcon from "../../assets/getting-started/buy-icon.png";
import RocketIcon from "../../assets/getting-started/rocket-icon.png";

function GettingStartedHome() {
  return (
    <div className="my-14 px-5 sm:px-9 md:mx-20 xl:mx-40">
      {/* Header */}
      <div className="mb-14">
        <p className="mb-5 text-center text-4xl font-medium text-white md:text-5xl">
          Getting started
        </p>
        <p
          className="text-1xl text-center text-secondary-100 md:text-2xl"
          data-aos="zoom-y-out"
        >
          Here's what you need to know before starting to use Kuri Plugins
        </p>
      </div>
      {/* Content */}
      <div className="relative" data-aos="zoom-y-out">
        <div
          className="absolute mt-11 h-[53px] w-full rounded-xl blur-3xl"
          style={{
            background:
              "linear-gradient(125.77deg, #9b51e0 7.57%, #3081ed 94.02%)",
          }}
        />
        <div className="flex gap-10 overflow-x-auto pb-3 md:justify-center md:gap-40">
          <div>
            <div className="getting-started-box flex h-[138px] w-[150px] items-center justify-center rounded-3xl bg-white/[0.09]">
              <img src={BuyIcon} alt="" className=" h-[60px] w-[60px]" />
            </div>
            <p className="mt-5 text-center text-base text-white">
              Make a purchase
            </p>
          </div>
          <div>
            <div className="getting-started-box flex h-[138px] w-[150px] items-center justify-center rounded-3xl bg-white/[0.09]">
              <img src={DiscordIcon} alt="" className=" h-[60px] w-[60px]" />
            </div>
            <p className="mt-5 text-center text-base text-white">
              Access our discord
            </p>
          </div>
          <div>
            <div className="getting-started-box flex h-[138px] w-[150px] items-center justify-center rounded-3xl bg-white/[0.09]">
              <img src={DownloadIcon} alt="" className=" h-[60px] w-[60px]" />
            </div>
            <p className="mt-5 text-center text-base text-white">
              Download Kuri
              <br />
              Launcher
            </p>
          </div>
          <div>
            <div className="getting-started-box flex h-[138px] w-[150px] items-center justify-center rounded-3xl bg-white/[0.09]">
              <img src={RocketIcon} alt="" className=" h-[60px] w-[60px]" />
            </div>
            <p className="mt-5 text-center text-base text-white">
              Enjoy your plugins
            </p>
          </div>
        </div>
        <div />
      </div>
    </div>
  );
}

export default GettingStartedHome;
