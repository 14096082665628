import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import ProductCard from "../../components/product-card/ProductCard";
import { useGlobalContext } from "../../context/useGlobalContext";
import {
  IdentifiedSellixPlugin
} from "../../services/sellix";

function Plugins() {
  const { plugins } = useGlobalContext();

  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      {/*  Site header */}
      <Header />

      <div className="pt-10 md:pt-40">
        {/*  Page content */}
        <section className="">
          <div className="px-5 py-14 sm:px-6 md:mx-20 xl:mx-40">
            <div className="pb-12 md:pb-20">
              {/* Header */}
              <div className="mb-10 flex 2xl:mx-auto 2xl:w-5/6">
                <div className="flex-wrap justify-start text-left text-2xl font-medium text-white md:text-5xl">
                  Plugins
                </div>
              </div>
              {/* Plugin list */}
              <div
                className="grid gap-7 auto-fit-[280px] 2xl:mx-auto 2xl:w-5/6"
                data-aos="zoom-y-out"
              >
                {plugins.map(
                  (plugin: IdentifiedSellixPlugin, index: number) => (
                    <div className="mx-auto snap-start">
                      <ProductCard
                        product={plugin}
                        key={index}
                        id={plugin.id}
                        img={plugin.price.amount > 0 ? plugin.sellixProducts[0].imageUrl : `/assets/${plugin.id}/thumbnail.jpg`}
                        name={plugin.name}
                        version={plugin.version}
                        price={plugin.price.amount}
                        categories={plugin.categories}
                      />
                    </div>
                  ),
                )}
              </div>
            </div>
          </div>
        </section>
      </div>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Plugins;
