import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

function About() {
  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      {/*  Site header */}
      <Header />

      <main className="flex-grow px-5 pt-10 xl:mx-96 xl:px-32 xl:pt-40">
        <h1
          className="bg-gradient-to-r from-purple-400 via-purple-500 to-blue-600 bg-clip-text pt-16 text-center text-4xl font-bold leading-[105px] text-transparent md:mb-6 md:text-6xl"
          data-aos="zoom-y-out"
        >
          About us
        </h1>
        <p
          className="mb-20 text-center text-base font-normal text-secondary-200 md:mx-32 md:text-base"
          data-aos="zoom-y-out"
          data-aos-delay="150"
        >
          Kuri Plugins focuses on providing overpowered, non-automated plugins
          that you can safely use on your main account.
        </p>
        <div
          className="mx-auto mb-8 mt-4 h-0.5 w-auto bg-gradient-to-r from-pink-400 via-purple-500 to-blue-600"
          data-aos="zoom-y-out"
          data-aos-delay="150"
        ></div>
        <h2
          className="mb-5 text-left text-3xl font-semibold text-white md:text-4xl"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          Our Story
        </h2>
        <p
          className="mb-20 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          Our journey began with a simple yet powerful idea: to make the OSRS
          experience better, more relaxed, and more enjoyable. Our goal is to
          ensure that every moment you spend playing OSRS is filled with
          enjoyment, eliminating the tedious aspects of the game or at least
          reducing the attention they require.
        </p>
        <h2
          className="mb-5 text-left text-3xl font-semibold text-white md:text-4xl"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          Prologue
        </h2>
        <p
          className="mb-20 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          Before Kuri Plugins, the OpenOSRS era saw the development of several
          plugins by Kuri, such as one-click-wintertodt, one-click-tempoross,
          one-click-gotr, and gauntlet-helper, among others. When Jagex shut
          down that project, I decided to create Kuri Plugins, a safe space
          where players can enjoy the benefits of a RuneLite client with no
          plugin restrictions.
        </p>
        <h2
          className="mb-5 text-left text-3xl font-semibold text-white md:text-4xl"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          Where we are now
        </h2>
        <p
          className="mb-3 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          After 1.5 years in development, Kuri Plugins is now open to public,
          offering a range of plugins:
        </p>
        <ul
          className="mb-20 ml-4 list-disc text-left text-base leading-relaxed text-white md:ml-10 md:text-lg"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          <li>
            <b>PvM Helpers</b>: Features like auto pray switching, auto gear
            switching, and custom overlays for major encounters such as
            <b>Zulrah, Hunllef,</b> and more.
          </li>
          <li>
            <b>PvP Helpers</b>: Tools for auto pray, gear, spell casting, and
            custom triggers. Includes overlays and scripts for enhanced PvP
            performance—don’t miss the <b>Kuri gear swapper</b> for an edge in
            PvP.
          </li>
          <li>
            <b>One-Click Skilling Plugins</b>: Efficient, one-click solutions
            for every skill—whether it’s <b>Agility, Construction</b>, or any
            other skill you're leveling.
          </li>
          <li>
            <a href="/store">... And more!</a>
          </li>
        </ul>
        <h2
          className="mb-5 text-left text-3xl font-semibold text-white md:text-4xl"
          data-aos="zoom-y-out"
        >
          Join Us: Craft the Future of OSRS
        </h2>
        <p
          className="mb-5 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
        >
          At Kuri Plugins, we're always on the lookout for passionate
          individuals who share our commitment to creating exceptional products.
          If you're enthusiastic about OSRS and want to be part of a team that's
          redefining the gaming experience, you've come to the right place.
        </p>
        <p
          className="mb-20 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
        >
          Join Kuri Plugins and become a part of a vibrant community that's
          revolutionizing the OSRS experience. Together, we're building a space
          where every gamer can find a sense of belonging.
        </p>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default About;
