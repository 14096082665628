import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import GettingStartedHome from "../home/GettingStartedHome";

function GettingStarted() {
  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      {/*  Site header */}
      <Header />
      <div className="xl:mt-40">
        <GettingStartedHome />
      </div>
      <div className="flex-grow px-5 xl:mx-96 xl:px-32">
        <h2
          className="mb-5 text-left text-3xl text-white md:text-4xl"
          data-aos="zoom-y-out"
          data-aos-delay="150"
        >
          Make a purchase
        </h2>
        <p
          className="mb-5 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
          data-aos-delay="150"
        >
          Visit our{" "}
          <Link to="/store">
            <span className="text-blue-600 underline">store</span>{" "}
          </Link>
          to explore and purchase a plugin that fits your needs!
        </p>
        <p
          className="mb-20 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
          data-aos-delay="150"
        >
          Choose from individual plugin subscriptions or opt for a bundle that
          includes multiple plugins. On the bundle page, you can see the plugins
          included, and on each plugin page, you'll find detailed feature
          descriptions.
        </p>
        <h2
          className="mb-5 text-left text-3xl text-white md:text-4xl"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          Access our discord
        </h2>
        <p
          className="mb-20 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
          data-aos-delay="300"
        >
          After your purchase, join our{" "}
          <a
            className="text-blue-600 underline"
            href="https://discord.gg/3bhXYtd8bt"
          >
            Discord
          </a>{" "}
          community to download our launcher and stay connected.
        </p>
        <h2
          className="mb-5 text-left text-3xl text-white md:text-4xl"
          data-aos="zoom-y-out"
          data-aos-delay="450"
        >
          Download Kuri Launcher
        </h2>
        <p
          className="mb-20 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
          data-aos-delay="450"
        >
          Once you're in our Discord, head over to the{" "}
          <a
            className="text-blue-600 underline"
            href="https://discord.com/channels/1094266705909403749/1133105078635610202"
          >
            # 🚀︱launcher-updates
          </a>{" "}
          to download and set up the Kuri Launcher.
        </p>
        <h2
          className="mb-5 text-left text-3xl text-white md:text-4xl"
          data-aos="zoom-y-out"
        >
          Enjoy our plugins!
        </h2>
        <p
          className="mb-20 text-left text-base leading-relaxed text-white md:text-lg"
          data-aos="zoom-y-out"
        >
          With the launcher installed, log in using your Discord account. Start
          exploring the wide range of Kuri Plugins, and don't forget to share
          your feedback—we're always working to improve!
        </p>
      </div>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default GettingStarted;
