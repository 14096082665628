/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SellixBundlesGet } from '../models/SellixBundlesGet';
import type { SellixPluginsGet } from '../models/SellixPluginsGet';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SellixService {
    /**
     * Get list of sellix bundle products
     * @returns SellixBundlesGet OK
     * @throws ApiError
     */
    public static getSellixBundles(): CancelablePromise<SellixBundlesGet> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/client-api/v1/sellix/bundles',
        });
    }
    /**
     * Get list of sellix plugin products
     * @returns SellixPluginsGet OK
     * @throws ApiError
     */
    public static getSellixPlugins(): CancelablePromise<SellixPluginsGet> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/client-api/v1/sellix/plugins',
        });
    }
}
