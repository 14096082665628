import React, { memo } from "react";
import CloseIcon from "../../assets/close-icon.png";
import { useGlobalContext } from "../../context/useGlobalContext";
import { IdentifiedSellixProduct } from "../../services/sellix";

const CartModal = memo(function CartModal() {
  const { selectedProduct, setSelectedProduct, setSellixProduct } =
    useGlobalContext();
  if (!selectedProduct) return null;

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  const handleProductPurchase = (sellixProduct: IdentifiedSellixProduct) => {
    setSellixProduct(sellixProduct);
  };

  const getSaveAmount = (sellixProduct: IdentifiedSellixProduct) => {
    const saveAmount = (
      selectedProduct.price.amount * sellixProduct.period -
      selectedProduct.price.amount *
        sellixProduct.period *
        (1 - sellixProduct.discount)
    ).toFixed(2);
    return Number(saveAmount) > 0 ? `Save ${saveAmount}€` : "";
  };

  const getOrderedSellixProducts = (
    sellixProducts: IdentifiedSellixProduct[],
  ) => {
    return sellixProducts.sort((a, b) => b.period - a.period);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 sm:p-6 md:p-8">
      <div
        className="absolute inset-0 scale-105 animate-fade-in bg-black bg-opacity-60 opacity-0 backdrop-blur-md transition-opacity duration-300 ease-out"
        onClick={handleCloseModal}
      ></div>
      <div className="relative mb-6 mt-16 max-h-screen w-full max-w-xl scale-95 transform transform animate-modal-slide-in overflow-y-auto rounded-2xl bg-black bg-opacity-80 p-6 opacity-0 shadow-2xl transition-transform duration-300 ease-in-out md:mb-12 md:mt-20 md:max-w-4xl md:p-8 lg:max-w-5xl">
        <div className="absolute right-6 top-5 text-white">
          <img
            className="cursor-pointer transition-all duration-200 hover:opacity-10"
            src={CloseIcon}
            height={20}
            width={20}
            alt="X"
            onClick={handleCloseModal}
          />
        </div>
        <div className="mb-6 text-center md:mb-8">
          <h1 className="bg-gradient-to-r from-pink-400 via-purple-500 to-blue-600 bg-clip-text text-4xl font-extrabold text-transparent md:text-5xl">
            Purchase
          </h1>
          <p className="mt-4 text-xs leading-relaxed text-gray-300 md:text-sm">
            Please note that all purchases of Kuri plugins are final. We do not
            accept refunds or exchanges once a transaction has been completed.
            By purchasing a Kuri plugin, you acknowledge and agree to this
            policy. If you have any questions or concerns prior to your
            purchase, please feel free to reach out for assistance. Thank you
            for your understanding!
          </p>
        </div>

        <div className="rounded-lg bg-gray-800 bg-opacity-30 p-4 md:p-6">
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto text-left">
              <tbody>
                {getOrderedSellixProducts(selectedProduct.sellixProducts).map(
                  (sellixProduct, index) => (
                    <tr
                      key={index}
                      className={`flex flex-wrap ${
                        index === 0 ? "pt-0" : "border-t border-gray-600"
                      } py-5 transition-colors duration-300 hover:bg-black hover:bg-opacity-50 sm:table-row sm:border-none sm:py-0`}
                    >
                      <td className="w-full px-4 py-3 text-left text-lg font-medium text-white sm:w-auto sm:px-6 sm:py-4 md:text-xl">
                        {sellixProduct.period}{" "}
                        {sellixProduct.period === 1 ? "Month" : "Months"}{" "}
                        Subscription
                      </td>
                      <td className="w-full px-4 py-3 text-left sm:w-auto sm:px-6 sm:py-4">
                        <div className="text-gray-100">
                          <span className="block text-xl font-semibold md:text-2xl">
                            {(
                              (selectedProduct.price.amount *
                                sellixProduct.period *
                                (1 - sellixProduct.discount)) /
                              sellixProduct.period
                            ).toFixed(2)}
                            € / Month
                          </span>
                          <span className="text-xs text-gray-600 md:text-sm">
                            Total{" "}
                            {(
                              selectedProduct.price.amount *
                              sellixProduct.period *
                              (1 - sellixProduct.discount)
                            ).toFixed(2)}
                            €
                          </span>
                        </div>
                      </td>
                      <td className="w-full px-4 py-3 text-left text-green-400 sm:w-auto sm:px-6 sm:text-right">
                        {getSaveAmount(sellixProduct)}
                      </td>
                      <td className="w-full px-4 py-3 text-right sm:w-auto sm:px-6">
                        <button
                          onClick={() => handleProductPurchase(sellixProduct)}
                          className="btn-gradient w-full transform rounded-lg px-4 py-2 font-semibold text-white shadow-lg transition-all duration-200 hover:scale-105 hover:bg-white active:scale-95 sm:w-auto md:px-6"
                        >
                          Purchase
                        </button>
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CartModal;
